import React from 'react';
import './Footer.css'
import footerLogo from '../../Assets/Uniplay-logo.png'
import twitter from '../../Assets/social-twitter.png'
import Telegram from '../../Assets/social-telegram.png'
import Medium from '../../Assets/social-medium-blog.png'
import { Grid, Typography, Box } from '@material-ui/core';

function Footer() {
    const year = new Date().getFullYear();
  return (
    <>
      <div className='footerUniplay'>
        <Grid container lg={12} md={12} sm={12} xs={12} alignItems='center' alignContent={'center'}justifyContent='space-evenly' direction='row'>
          <Grid alignContent={'center'}justifyContent='flex-start' container lg={6} md={6} sm={6} xs={6}>
            <img src={footerLogo} alt='footer-logo' className="footer_logo_img"/>
          </Grid>
          <Grid alignContent={'center'}justifyContent='flex-end' container lg={6} md={6} sm={6} xs={6}>
            <Typography className="footer_tagline">&#9400;  {year} UniPlay</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Footer
