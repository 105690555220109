import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet, connectFailed } from "../../redux/WalletAction";
import "./Home.css";

import logo from '../../Assets/Uniplay_logo.png';




function Home() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.WalletConnect);

  const connect = () => {
    console.log("connect");
    dispatch(connectWallet());
  };

  const errorDiv = () => {
    return <p>Wallet Disconnected!</p>;
  };
  const disconnect = () => {
    const { web3Modal } = wallet;
    web3Modal.clearCachedProvider();
    dispatch(connectFailed(errorDiv()));
  };
  return (
    <>
    <div className=" container-fluid">
      <div className="background-section">
        <div className="row home" id="background-img">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <img src={logo} className="homePage_logo"/>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            {!wallet.connected && (
                <button className="btn btn-primary" onClick={connect}>
                  Connect Wallet 
                </button>
              )}
          </div>
        </div>
      </div> 
    </div>
    </>
  );
}

export default Home;
