import React from 'react'
import Navbar from '../Navbar/Navbar';
import "./Admin.css"
import cancel from '../../Assets/cancel.png'
import Footer from '../Footer/Footer';
import Tokenlogo from '../../Assets/token.png'
import { useDispatch, useSelector } from "react-redux";
import { connectWallet, connectFailed } from "../../redux/WalletAction";
import { useState } from "react"
import { useEffect } from "react"
import axios from "axios"
import loader from '../../Assets/ZZ5H.gif'
import Searchicon from '../../Assets/search.png'
import { Grid, Box, Typography } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'bootstrap';

function Admin(props) {
  const wallet = useSelector((state) => state.WalletConnect);

  const [tokenAmount, settokenAmount] = useState("");
  const [investoraddress, setinvestoraddress] = useState();
  const [addinvestors, setaddinvestor] = useState("");
  const [tokenavailablee, settokenavailablee] = useState("0");
  const [starttime, setstarttime] = useState("");
  const [searchInput, setsearchInput] = useState("");
  const [lists, setlists] = useState([]);
  const [investorshistory, setinvestorshistory] = useState([]);
  const [lists1, setlists1] = useState([]);
  const [investorslist, setinvestorslist] = useState([]);
  const [filterRecords, setfilterRecords] = useState([]);
  const [total, settotal] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("")
  const [isAprroved, setApproved] = useState(true)
  const [allowace, setAllowance] = useState(0);
  const [Searchresult, setSearchresult] = useState('');
  const [Searchresultamt, setSearchresultamt] = useState('');

  const [load1, setload1] = useState(false);
  const [load2, setload2] = useState(false);
  const [load3, setload3] = useState(false);
  const [load4, setload4] = useState(false);
  const [load5, setload5] = useState(false);
  const [uploading, setuploading] = useState(false)
  const userdetails = async () => {
    const { connected } = wallet;
    if (!connected) {
      await connectWallet();
    } if (wallet.vesting != null) {

    }
  }


  useEffect(() => {
    userdetails()
    if (wallet.connected) {

      fetchTransactionHistory();
      availabletokens();
      startvestingtime();
      detailss();
    }
  }, [wallet.connected == true])

  function datahandeling(e) {
    setaddinvestor(e.target.value)
  }

  const detailss = async () => {
    setload5(true)
    const { address, web3 } = wallet;
    const result1 = await axios.get(process.env.REACT_APP_ETHERSCAN_API, {
      params: {
        module: 'logs',
        action: 'getLogs',
        address: process.env.REACT_APP_VESTING_CONTRACT_ADDRESS,
        topic0: process.env.REACT_APP_TOPIC0_INVESTERS_ADDRESS,
        apikey: process.env.REACT_APP_ETHERSCAN_API_KEY
      }
    });

    const resultArray1 = result1.data.result;
    const reversedList1 = resultArray1.map((e, i, a) => a[(a.length - 1) - i])
    const transactionHistory = [];
    const transactionHistoryamt = [];
    await Promise.all(reversedList1.map(async (event, i) => {
      const { vesting } = wallet;
      const transactionHash = event.transactionHash;
      var data1 = await web3.eth.abi.decodeLog(
        [
          { "indexed": false, "internalType": "address", "name": "accountt", "type": "address" },
          { "indexed": false, "internalType": "uint", "name": "_amount", "type": "uint" },
          { "indexed": false, "internalType": "uint", "name": "saletype", "type": "uint" },
        ], event.data)
      var useraddress = data1.accountt
      const removead = await vesting.methods.Investors(useraddress).call()
      var nonce = Date.now() / 1000;
      var yourNumber = new Date(parseInt(event.timeStamp) * 1000).toDateString();
      if (removead.investorType != 0 && removead.investorType == data1.saletype) {
        var amount = data1._amount
        var saleType1 = ""


        if (data1.saletype == '1') {
          var saleType1 = "seed"
        } else
          if (data1.saletype == '2') {
            var saleType1 = "private"
          } else
            if (data1.saletype === '3') {
              var saleType1 = "Team"
            } else
              if (data1.saletype === '4') {
                var saleType1 = "launcpad"
              } else
                if (data1.saletype === '5') {
                  var saleType1 = "Market"
                } else
                  if (data1.saletype === '6') {
                    var saleType1 = "Airdrop"
                  }
        var eventtime = event.timeStamp
        const transaction = { transactionHash, useraddress, amount, saleType1, time, index1: i + 1, eventtime };
        transactionHistoryamt.push(amount, amount)
        transactionHistory.push(transaction);

      }
    }));
    var topValueseth = transactionHistory.sort((a, b) => b.eventtime - a.eventtime)
    setlists1(transactionHistory);
    setinvestorslist(transactionHistory);
    setload5(false)
  }

  const availabletokens = async () => {
    const { vesting, address, web3 } = wallet;
    const availabletoken = await vesting.methods.getContractTokenBalance().call();

    const tokenavailable = parseFloat(web3.utils.fromWei(availabletoken, 'ether')).toFixed(2);
    settokenavailablee(tokenavailable)

  }

  const timeconvert = (unix_timestamp) => {
    var date = new Date(unix_timestamp * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    var formattedTime = date + ":" + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime
  }

  const startvestingtime = async () => {
    const { vesting, address, web3 } = wallet;
    var startt = await vesting.methods.teamStartDate().call();

    if (startt == 0) {
      setstarttime("TGE Starts Soon")
    } else {
      var timetostart = timeconvert(startt);

      const timestarted = (String((timetostart))).slice(4, 21);
      setstarttime(timestarted)
    }

  }
  const RemoveInvestors = async (data3) => {
    setinvestoraddress("")

    setload2(true)
    const { vesting, address, web3 } = wallet;

    try {

      const admindetails1 = await vesting.methods.removeSingleInvestor(data3).send({ from: address });

      detailss();
      setinvestoraddress("")
      setload2(false);
    }
    catch {
      setinvestoraddress("")
      setload2(false);
    }
  }
  const StartDate = async () => {
    setdate("")
    settime("")
    setload4(true)
    const { vesting, address, web3 } = wallet;
    try {

      const datee = date + ' ' + time;

      const dates = Math.floor(new Date(datee).getTime() / 1000)

      const startDate1 = await vesting.methods.setDates(dates, true).send({ from: address });
      startvestingtime();
      setdate("")
      settime("")
      setload4(false)
    }
    catch {
      setdate("")
      settime("")
      setload4(false)
    }
  }

  const AddInvestor = async () => {
    setload3(true)
    const { vesting, address, web3 } = wallet;
    try {
      var data = JSON.parse(addinvestors)
      const admindetails = await vesting.methods.adminAddInvestors(data).send({ from: address });

      await detailss();
      setaddinvestor("")
      setload3(false)
    }
    catch (err) {
      toast.error( err.message || err.code);
      setload3(false)
    }
  }
  const getTransactionData = async () => {
    const { address, web3 } = wallet;
    const result = await axios.get(process.env.REACT_APP_ETHERSCAN_API, {
      params: {
        module: 'logs',
        action: 'getLogs',
        address: process.env.REACT_APP_VESTING_CONTRACT_ADDRESS,
        topic0: process.env.REACT_APP_TOPIC0_TOKEN_WITHDRAW,
        apikey: process.env.REACT_APP_ETHERSCAN_API_KEY
      }
    });
    return result.data.result;
  }
  const fetchTransactionHistory = async () => {
    const { address, web3 } = wallet;
    const transactionHistory = [];
    // let rate = 0.026
    getTransactionData().then(async (resultArray) => {
      const reversedList = resultArray.map((e, i, a) => a[(a.length - 1) - i])
      await Promise.all(reversedList.map(async (event, i) => {
        const returnAddress = web3.utils.toChecksumAddress('0x' + event.topics[1].slice(-40));
        const transactionHash = event.transactionHash;
        const amount = parseFloat(web3.utils.fromWei(web3.utils.hexToNumberString(event.data), 'ether')).toFixed(2);
        const timees = timeconvert(event.timeStamp);
        var time = (String((timees))).slice(4, 15);
        const transaction = { transactionHash, amount, time, index: i + 1, address: returnAddress };
        // , usd: (rate*amount).toFixed(2)
        transactionHistory.push(transaction);
      }));
      var resultData = transactionHistory;
      setlists(transactionHistory)
      setinvestorshistory(transactionHistory)
      setfilterRecords(transactionHistory)
      settotal(Math.ceil(resultData.length / 5));
    });
  }

  const handlesearch = () => {
    if (Searchresult != '') {
      var data = investorslist.filter((res) => {
        return res.transactionHash == Searchresult || res.useraddress == Searchresult
      })
      setlists1(data)
    } else {
      setlists1(investorslist)
    }
  }
  const handlesearchamt = () => {
    if (Searchresultamt != '') {
      var data = investorshistory.filter((res) => {
        return res.transactionHash == Searchresultamt || res.address == Searchresultamt
      })
      setlists(data)
    } else {
      setlists(investorshistory)
    }
  }
  return (
    <>
      <Navbar />
      <div className='uniplayAdminWrapper'>
        <Grid container alignItems='center' justifyContent='center' lg={12} md={12} sm={12} xs={12} direction='row'>
          <Grid spacing={2} container alignItems='center' justifyContent='center' lg={12} md={12} sm={12}>
            <Grid item justifyContent='space-between' alignItems='center' lg={3} md={12} sm={12} xs={12} direction={'row'} >
              <Box container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-evenly", bgcolor: "#fff", padding: '20px 20px', borderRadius: '12px', flexDirection: 'column' }}>
                <Grid lg={12} container alignItems='center' justifyContent='center'>
                  <Grid container lg={12} md={12} sm={12} xs={12}>
                    <Box item >
                      <img src={Tokenlogo} className='token_Icon' />
                    </Box>
                    <Box container sx={{ marginLeft: '10px' }}>
                      <Typography paragraph className='adminBoxHeader' style={{ textAlign: 'left' }}>
                        Available Token Balance:
                      </Typography>
                      <Box item>
                        <Typography paragraph className='availablelabel' style={{ textAlign: 'left' }}>
                          {tokenavailablee}
                        </Typography>
                      </Box>
                      <Box item>
                        <Typography paragraph className='adminBoxHeader' style={{ textAlign: 'left' }}>
                          TGE Starts On :
                        </Typography>
                      </Box>
                      <Box item>
                        <Typography paragraph className='availablelabel' style={{ textAlign: 'left' }}>
                          {starttime}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item alignItems='center' xs={12} lg={3} md={12} sm={12} direction={'row'} >
              <Box container sx={{ bgcolor: "#fff", padding: '20px 0px', borderRadius: '12px', height: '182px' }}>
                <Grid container style={{ padding: "0px 10px" }} jus>
                  <Typography className='adminTopBoxHeader' paragraph>
                    Remove Investor
                  </Typography>
                </Grid>
                <Grid container style={{ padding: "16px" }} spacing={2} direction={'column'} >
                  <Grid item xs={12}>
                    <input
                      type='text'
                      className='removeInput'
                      value={investoraddress}
                      onChange={e => setinvestoraddress(e.target.value)}
                      placeholder="Remove Investor Address"
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex'}} justifyContent='flex-end'>
                    {
                      load2 ?
                        <button className='removeBtn' onClick={() => RemoveInvestors(investoraddress)}>Remove<img style={{ width: 20, height: 20 }} src={loader} /></button>
                        :
                        <button className='removeBtn' onClick={() => RemoveInvestors(investoraddress)}>Remove</button>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item justifyContent='space-between' alignItems='center' xs={12} lg={3} md={12} sm={12} direction={'row'} >
              <Box container sx={{ bgcolor: "#fff", padding: '20px 0px', borderRadius: '12px', height: '182px' }}>
                <Grid container style={{ padding: "0px 10px" }}>
                  <Typography paragraph className='adminTopBoxHeader'>
                    Add Investor
                  </Typography>
                </Grid>
                <Grid container style={{ padding: "16px" }} spacing={2} direction={'column'} >
                  <Grid item xs={12}>
                    <input
                      className='addInput'
                      type='text'
                      value={addinvestors}
                      onChange={e => datahandeling(e)}
                      placeholder="Add Investors"
                    />
                  </Grid>
                  <Grid item xs={12} style={{display: 'flex'}} justifyContent='flex-end'>
                    {
                      load3 ?
                        <button className='addBtn' onClick={AddInvestor}>Add<img style={{ width: 20, height: 20 }} src={loader} /></button>
                        :
                        <button className='addBtn' onClick={AddInvestor}>Add</button>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item justifyContent='space-between' alignItems='center' xs={12} lg={3} md={12} sm={12} xs={12} direction={'row'} >
              <Box container sx={{ bgcolor: "#fff", padding: '20px 0px', borderRadius: '12px', height: '182px' }}>
                <Grid container style={{ padding: "0px 10px" }}>
                  <Typography paragraph className='adminTopBoxHeader'>
                    Start Date
                  </Typography>
                </Grid>
                <Grid container style={{ padding: "0px 10px" }} alignItems='center' justifyContent='center' direction='column'>
                  <input
                    className='inpstart1'
                    type='date'
                    value={date}
                    onChange={e => setdate(e.target.value)}
                    placeholder="Add Investors"
                  />
                  <input
                    className='inpstart2'
                    type='time'
                    value={time}
                    onChange={e => settime(e.target.value)}
                    placeholder="Add Investors"
                  />
                  {
                    load4 ?
                      <button className='btnstart' onClick={StartDate}>Start Date<img style={{ width: 20, height: 20 }} src={loader} /></button>
                      :
                      <button className='btnstart' onClick={StartDate}>Start Date</button>
                  }
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid alignItems='center' justifyContent='space-evenly' lg={12} md={12} sm={12} xs={12} direction='row' style={{ marginTop: '16px' }}>
          <Grid spacing={2} container>
            <Grid item justifyContent='space-between' alignItems='center' xs={12} lg={6} md={12} sm={12} direction={'row'} >
              <Grid style={{ background: '#fff', borderRadius: '20px'}}>
                <Grid container className='AdminWalletBox' justifyContent={'flex-end'} style={{padding: 16}}>
                  <Grid style={{ flexDirection: 'row', display: 'flex' }} lg={12} md={12} sm={12} xs={12} >
                      <input type={'text'} value={Searchresultamt} className='search' onChange={(e) => setSearchresultamt(e.target.value)} placeholder={'Hash / Investor Address'} style={{ borderRadius: '10px', alignContent: 'center', borderColor: '#D8D8D8', width: '100%' }} />
                      <button onClick={() => handlesearchamt()} style={{ backgroundColor: 'transparent', border: '0px solid' }} >
                        <img src={Searchicon} style={{ width: '20px', height: '20px' }} alt='' className="cancelimg" />  </button>
                  </Grid>
                </Grid>
                <Grid container className='AdminWalletBox' alignItems='center' justifyContent='center'>
                  <Grid container alignItems="center" justifyContent="center" lg={1} md={1} sm={1} xs={1}>
                    <Typography paragraph className='hash-head'>No</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                    <Typography paragraph className='hash-head'>Transaction Hash</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" lg={1} md={1} sm={1} xs={1}>
                    <Typography paragraph className='hash-head'>Wallet</Typography>
                  </Grid>
                  {/* <Grid container alignItems="center" justifyContent="center" lg={2} md={2} sm={2} xs={2}>
                  <Typography paragraph className='hash-head'>USD</Typography>
                </Grid> */}
                  <Grid container alignItems="center" justifyContent="center" lg={2} md={2} sm={2} xs={2}>
                    <Typography paragraph className='hash-head'>UNP</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                    <Typography paragraph className='hash-head'>Transaction Date</Typography>
                  </Grid>
                </Grid>
                <Box container className={'transactionhashcontainer'} sx={{ display: 'flex', alignItems: "center", flexDirection: 'column', padding: '10px 20px', borderRadius: '12px' }} >
                  <Grid style={{display: 'flex', flexDirection: "column", width: '100%'}}>
                    {lists.map((data, i) => {
                      const link = process.env.REACT_APP_ETHERSCAN_TRANSACTION_HASHLINK + '' + data.transactionHash;
                      return (
                          <Grid container item key={data.transactionHash} xs={12} className='databg' alignItems='center' justifyContent='center'>
                            <Grid container alignItems="center" justifyContent="center" md={1} sm={1} xs={1}>
                              <Typography paragraph className='hash-no'>{i}</Typography>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" md={3} sm={3} xs={3}>
                              <a href={link} target="blank" key={i}>
                                <Typography paragraph className='hash-value'>{data.transactionHash.slice(0, 4) + '...' + data.transactionHash.slice(-4)}</Typography>
                              </a>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" lg={1} md={1} sm={1} xs={1}>
                              <Typography paragraph className='hash-value'>{data.address.slice(0, 4) + '...' + data.address.slice(-4)}</Typography>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" lg={2} md={2} sm={2} xs={2}>
                              <Typography paragraph className='hash-value'>{data.amount}</Typography>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                              <Typography paragraph className='hash-value'>{data.time}</Typography>
                            </Grid>
                          </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item justifyContent='center' alignItems='center' xs={12} lg={6} md={12} sm={12} >
              <Grid style={{ background: '#fff', borderRadius: '20px'}}>
                <Grid container className='AdminWalletBox' alignItems='center' justifyContent='flex-end' style={{padding: 16}}>
                  <Grid style={{flexDirection: 'row', display: 'flex' }} xl={12} lg={12} md={12} sm={12} xs={12} >
                      <input type={'text'} value={Searchresult} className='search' onChange={(e) => setSearchresult(e.target.value)} placeholder={'Hash  / Investor Address'} style={{ borderRadius: '10px', alignContent: 'center', borderColor: '#D8D8D8',width: '100%' }} />
                      <button onClick={() => handlesearch()} style={{ backgroundColor: 'transparent', border: '0px solid' }} >
                        <img src={Searchicon} style={{ width: '20px', height: '20px' }} alt='' className="cancelimg" />  </button>
                  </Grid>
                </Grid>
                <Grid container className='AdminWalletBox' alignItems='center' justifyContent='center'>
                  <Grid container alignItems="center" justifyContent="center" lg={1} md={1} sm={1} xs={1}>
                    <Typography paragraph className='hash-head'>No</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                    <Typography paragraph className='hash-head'>Transaction Hash</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                    <Typography paragraph className='hash-head'>Investor Address</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                    <Typography paragraph className='hash-head'>Amount Vested</Typography>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" lg={2} md={2} sm={2} xs={2}>
                    <Typography paragraph className='hash-head'>Sale Type</Typography>
                  </Grid>
                </Grid>

                <Box container className={'transactionhashcontainer'} sx={{ display: 'flex', alignItems: "center", flexDirection: 'column', padding: '10px 20px', borderRadius: '12px' }} >
                  {load5 != "" ? <img style={{ width: 20, height: 20 }} src={loader} /> :
                    <Grid style={{display: 'flex', flexDirection: "column", width: '100%'}}>
                      {lists1.map((data1, i) => {
                        const link1 = process.env.REACT_APP_ETHERSCAN_TRANSACTION_HASHLINK + '' + data1.transactionHash;
                        return (
                          <Grid container lg={12} alignItems='center' justifyContent='center' className='databg' >
                            <Grid container alignItems="center" justifyContent="center" md={1} sm={1} xs={1}>
                              <Typography paragraph className='hash-no'>{i}</Typography>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                              <a className='stack' href={link1} target="blank" key={i}>
                                <Typography paragraph className='hash-value1'>{data1.transactionHash.slice(0, 4) + '...' + data1.transactionHash.slice(-4)}</Typography>
                              </a>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                              <Typography paragraph className='hash-value'>{data1.useraddress.slice(0, 4) + '...' + data1.useraddress.slice(-4)}</Typography>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" lg={3} md={3} sm={3} xs={3}>
                              <Typography paragraph className='hash-value'>{data1.amount}</Typography>
                            </Grid>
                            <Grid container alignItems="center" justifyContent="center" lg={1} md={1} sm={1} xs={1}>
                              <Typography paragraph className='hash-value'>{data1.saleType1}</Typography>
                            </Grid>
                            <Grid style={{ cursor: 'pointer' }} container alignItems="center" justifyContent="center" lg={1} md={1} sm={1} xs={1} >
                              <img className="cancelimg" src={cancel} alt='' onClick={() => RemoveInvestors(data1.useraddress)} />
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  )
}

export default Admin
