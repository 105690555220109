import React from 'react'
import Navbar from '../Navbar/Navbar';
import "./Dashboard.css"
import withdrawIcon from '../../Assets/icon111.png'
import purchaseIcon from '../../Assets/icon222.png'
import availableIcon from '../../Assets/available.png'
import Footer from '../Footer/Footer';
import { useDispatch, useSelector } from "react-redux";
import { connectWallet, connectFailed } from "../../redux/WalletAction";
import {useState,useEffect} from "react"
import axios from "axios";
import {Grid, Container, Typography, Box} from '@material-ui/core';
import loader from '../../Assets/ZZ5H.gif';
import { ThemeProvider, createTheme } from '@material-ui/core';

function Dashoboard(props) {
const wallet = useSelector((state) => state.WalletConnect);
const [totalTokenpurchased,settotalTokenpurchased] = useState("");
const [vestingBalance,setvestingBalance] = useState("");
const [availableToWithdraw,setavailableToWithdraw] = useState('');
const [availableToWithdraw1,setavailableToWithdraw1] = useState('');
const [tokenPerDay,settokenPerDay] = useState("");
const [endDate,setendDate] = useState("");
const [startDate,setstartDate] = useState("");
const [saleType,setsaleType] = useState("");
const [initialRelease,setinitialRelease] = useState("");
const [lockingPeriod,setlockingPeriod] = useState("");
const [splittedDte,setsplittedDate] = useState("");
const [lists,setlists]=useState([]);
const [totalwithdraw ,settotalwithdraw] =useState('')
const [load,setload] = useState(true);
const [vestingstarttime,setvestingstarttime] = useState(false);
const theme = createTheme({
  typography: {
    fontFamily: [
      'Raleway', 'sans-serif'
    ].join(",")
  }
});



const userdetails = async () =>{
    const { connected } = wallet;
     if (!connected) {
      await connectWallet();
   }
}
 useEffect( ()=>{  
    userdetails()
    if(!(lists.length > 0)) {
      
        fetchTransactionHistory();
      }
 },[wallet.connected == true])
 
const getInvestorDetails = async(sum) => {
   

    const { vesting, address, web3 } = wallet;

    const details = await vesting.methods.Investors(address).call();
   
    const totalBalance = parseFloat(web3.utils.fromWei(details.totalBalance, 'ether')).toFixed(2);
    const vestingBalances = parseFloat(web3.utils.fromWei(details.vestingBalance, 'ether')).toFixed(2);
  
    const tokensPerDay= parseFloat(web3.utils.fromWei(details.tokensPerUnit, 'ether')).toFixed(2);
    settokenPerDay(tokensPerDay)
  
    settotalTokenpurchased( totalBalance)
    setvestingBalance(vestingBalances)   
   
   
   var isstart = await vesting.methods.isStart().call();
  
   if(isstart==false){
   setavailableToWithdraw("Wait for the start time")
   }
   else{
    const details1 = await vesting.methods.getAvailableBalance(address).call();
    
    const availableToWithdraw = parseFloat(web3.utils.fromWei(details1[0], 'ether')).toFixed(2);
   
    setavailableToWithdraw(availableToWithdraw);
   }



 let saleType = details.investorType;

var nowtime = Math.round(Date.now()/1000);

 if(saleType === '1') {
  var startt11 = await vesting.methods.seedVestingEndDate().call();
  if(startt11==0){
   setendDate("TGE Starts soon")
  }else{
  setendDate(new Date(parseInt(startt11) * 1000).toDateString())
  }
  setsaleType('Seed');
  var startt1 = await vesting.methods.seedLockEndDate().call();
  if(startt1==0){
   setstartDate("TGE Starts soon")
  }else{
  setstartDate( new Date(parseInt(startt1) * 1000).toDateString())
  }
  setinitialRelease(`(5%) - ${Math.floor((totalBalance * 5)/100)} UNP`);
  setlockingPeriod( '1 Months');



  var isstart = await vesting.methods.isStart().call();
  
  if(isstart==false){
    setavailableToWithdraw("Wait for the start time")
   }else{

var veststartseed = await vesting.methods.seedStartDate().call();

if(nowtime > veststartseed){
  const details1 = await vesting.methods.getAvailableBalance(address).call();
 
  const availableToWithdraw = parseFloat(web3.utils.fromWei(details1[0], 'ether')).toFixed(2);
 
  setavailableToWithdraw(availableToWithdraw);
}
  else{
      setavailableToWithdraw("Wait for the start time")
     }}
}
//////////////////////////////////////////////////////////////////////////////////////
if(saleType === '2'){
  var startt12 = await vesting.methods.privateVestingEndDate().call();
  if(startt12 == 0){
   setendDate("TGE Starts soon")
  }else{
  setendDate( new Date(parseInt(startt12) * 1000).toDateString())
  }
  setsaleType ('Private')
  var startt2 =await vesting.methods.privateLockEndDate().call();
  if(startt2==0){
    setstartDate("TGE Starts soon")
  }
  else{
  setstartDate( new Date(parseInt(startt2) * 1000).toDateString())
  }
  setinitialRelease( `(8%) - ${Math.floor((totalBalance * 8)/100)} UNP`);
  setlockingPeriod('1 Month');


  var isstart = await vesting.methods.isStart().call();
  
  if(isstart==false){
    setavailableToWithdraw("Wait for the start time")
   }else{

var veststartprivate = await vesting.methods.privateStartDate().call();

if(nowtime > veststartprivate){
  const details1 = await vesting.methods.getAvailableBalance(address).call();
 
  const availableToWithdraw = parseFloat(web3.utils.fromWei(details1[0], 'ether')).toFixed(2);

  setavailableToWithdraw(availableToWithdraw);
}
  else{
     
      setavailableToWithdraw("Wait for the start time")
     }}


}
///////////////////////////////////////////////////////////////////////////////
if(saleType === '3') {

 var startt13 =await vesting.methods.teamVestingEndDate().call();
 if(startt13==0){
       setendDate("TGE Starts soon")
 }else{
   setendDate( new Date(parseInt(startt13) * 1000).toDateString())
 }
   setsaleType('Team')

   var startt3=await vesting.methods.teamLockEndDate().call();
 if(startt3==0){
   setstartDate("TGE Starts soon")
 }else{
   setstartDate(new Date(parseInt(startt3) * 1000).toDateString())
 }
   setinitialRelease( `(0%) - ${Math.floor((totalBalance * 0)/100)} UNP`);
   setlockingPeriod( '6 Month');

   var isstart = await vesting.methods.isStart().call();


   if(isstart==false){
    setavailableToWithdraw("Wait for the start time")
   }else{

var veststartteam = await vesting.methods.teamLockEndDate().call();

if(nowtime > veststartteam){
  const details1 = await vesting.methods.getAvailableBalance(address).call();
 
  const availableToWithdraw = parseFloat(web3.utils.fromWei(details1[0], 'ether')).toFixed(2);
 
  setavailableToWithdraw(availableToWithdraw);
}
  else{
     
      setavailableToWithdraw("wait for vesting date")
     }}
}
//////////////////////////////////////////////////////////////////////////////////////////
if(saleType === '4') {

  var start4 =await vesting.methods.launchpadVestingEndDate().call();
  if(start4==0){
        setendDate("TGE Starts soon")
  }else{
    setendDate( new Date(parseInt(start4) * 1000).toDateString())
  }
    setsaleType('Launchpad')
 
    var startt14=await vesting.methods.launchpadLockEndDate().call();
  if(startt14==0){
    setstartDate("TGE Starts soon")
  }else{
    setstartDate(new Date(parseInt(startt14) * 1000).toDateString())
  }
    setinitialRelease( `(10%) - ${Math.floor((totalBalance * 10)/100)} UNP`);
    setlockingPeriod( '1 Month');


    var isstart = await vesting.methods.isStart().call();
  
    if(isstart==false){
      setavailableToWithdraw("Wait for the start time")
     }else{
  
  var veststartlaunchpad = await vesting.methods.launchpadStartDate().call();
  
  if(nowtime > veststartlaunchpad){
    const details1 = await vesting.methods.getAvailableBalance(address).call();
    
    const availableToWithdraw = parseFloat(web3.utils.fromWei(details1[0], 'ether')).toFixed(2);
 
    setavailableToWithdraw(availableToWithdraw);
  }
    else{
       
        setavailableToWithdraw("Wait for the start time")
       }}
  
 }
 //////////////////////////////////////////////////////////////////////////////////////////
if(saleType === '5') {

  var start5 =await vesting.methods.marketdevelopmentVestingEndDate().call();
  if(start5==0){
        setendDate("TGE Starts soon")
  }else{
    setendDate( new Date(parseInt(start5) * 1000).toDateString())
  }
    setsaleType('Market&Community')
 
    var startt15=await vesting.methods.marketdevelopmentLockEndDate().call();
  if(startt15==0){
    setstartDate("TGE Starts soon")
  }else{
    setstartDate(new Date(parseInt(startt15) * 1000).toDateString())
  }
    setinitialRelease( `(0%) - ${Math.floor((totalBalance * 0)/100)} UNP`);
    setlockingPeriod( '3 Month');
    var isstart = await vesting.methods.isStart().call();
      
    if(isstart==false){
      setavailableToWithdraw("Wait for the start time")
     }else{
      
  
    var veststartmarket = await vesting.methods.marketdevelopmentLockEndDate().call();

    if(nowtime > veststartmarket){
      const details1 = await vesting.methods.getAvailableBalance(address).call();
     
      const availableToWithdraw = parseFloat(web3.utils.fromWei(details1[0], 'ether')).toFixed(2);
    
      setavailableToWithdraw(availableToWithdraw);
      
    }
      else{
        setavailableToWithdraw("wait for vesting date")
         }
        }
 }
 //////////////////////////////////////////////////////////////////////////////////////////
if(saleType === '6') {

  var start6 =await vesting.methods.airdropcampaignVestingEndDate().call();
  if(start6==0){
        setendDate("TGE Starts soon")
  }else{
    setendDate( new Date(parseInt(start6) * 1000).toDateString())
  }
    setsaleType('AirdropCampaign')
 
    var startt16=await vesting.methods.airdropcampaignLockEndDate().call();
  if(startt16==0){
    setstartDate("TGE Starts soon")
  }else{
    setstartDate(new Date(parseInt(startt16) * 1000).toDateString())
  }
    setinitialRelease( `(0%) - ${Math.floor((totalBalance * 0)/100)} UNP`);
    setlockingPeriod( '1 Month');
   
    if(isstart==false){
      setavailableToWithdraw("Wait for the start time")
     }else{

  
    var veststartair = await vesting.methods.airdropcampaignLockEndDate().call();

    if(nowtime > veststartair){
      const details1 = await vesting.methods.getAvailableBalance(address).call();
     
      const availableToWithdraw = parseFloat(web3.utils.fromWei(details1[0], 'ether')).toFixed(2);
     
      setavailableToWithdraw(availableToWithdraw); 
    }
      else{
        setavailableToWithdraw("wait for vesting date")
         }
        }
 }






  setload(false);
}

 const withdraw = async() => {
  
   if(availableToWithdraw!='1'){
    setload(true);
    const { vesting, address, web3 } = wallet;
    try{
    const res = await vesting.methods.withdrawTokens().send({from: address}); 
        fetchTransactionHistory();
  }catch{
      setload(false);
    }
   
   }
  }
 const fetchTransactionHistory = async () => {
    const {address, web3} = wallet;
   
    if(web3!=null){

    
    const result = await axios.get(process.env.REACT_APP_ETHERSCAN_API, {
        params: {
            module: 'logs',
            action: 'getLogs',
            address: process.env.REACT_APP_VESTING_CONTRACT_ADDRESS,
            topic0: process.env.REACT_APP_TOPIC0_TOKEN_WITHDRAW,
            apikey: process.env.REACT_APP_ETHERSCAN_API_KEY
        }
    });
    const resultArray =  result.data.result;
  
    const reversedList = resultArray.map((e, i, a)=> a[(a.length -1) -i])
   
    const transactionHistory = [];
    const transactionHistoryamt = [];
    reversedList.forEach((event) => {
      const returnAddress = web3.utils.toChecksumAddress('0x' + event.topics[1].slice(-40));
      if(returnAddress === address) {
        const transactionHash = event.transactionHash;
        const amount = parseFloat(web3.utils.fromWei(web3.utils.hexToNumberString(event.data), 'ether')).toFixed(2);
       var  yourNumber = parseInt(event.timeStamp, 16)*1000;
    
      const time = (String (new Date(yourNumber))).slice(4,25);
    
        const transaction = {transactionHash, amount, time};
        transactionHistoryamt.push(amount)
        transactionHistory.push(transaction);
      }
    });
    let sum = 0;
    for (let i = 0; i < transactionHistoryamt.length; i++) {
      sum += parseInt(transactionHistoryamt[i])  ;
  }
  settotalwithdraw(sum)
   
    setlists( transactionHistory);
    await  getInvestorDetails(sum);

}
  }
  function numberWithCommas(x) {
    return x
}
  return (
    <>
    <Navbar/>
    <div className='dashBoardWrapper'>
      
    <Grid container lg={12} md={12} sm={12} xs={12} justifyContent='center' style={{marginBottom:'20px'}}>
      {/* 1st Boxes Row */}
      <Grid spacing={4} container alignItems='center' justifyContent='center' lg={12} md={12} sm={12}>
        <Grid item justifyContent='space-between' alignItems='center' lg={6} md={12} sm={12} direction={'row'} >
          <Box container sx={{display:'flex', alignItems:"center", justifyContent:"space-evenly", bgcolor:"#fff", padding:'30px 0px', borderRadius:'12px'}}>
            <div>
              <img className='available-img'src={availableIcon}></img>
            </div>
            <div style={{marginRight:"4px"}}>
              <p className='balance_key' >Available to withdraw</p>
              {
              vestingstarttime ?
              <p className='balance_key'> Wait for the start time</p>
              :
              <p className='balance_value'>{numberWithCommas(availableToWithdraw) }</p>
              }
            </div>
            <div>
              {
              vestingstarttime?
              <button disabled onClick={()=>withdraw()} className='withdraw_btn'>Withdraw </button>
              :
              load ? 
              <button disabled onClick={()=>withdraw()} className='withdraw_btn'>Withdraw
              <img style={{width:25, height:25}} src={loader} /></button>
              :
              <button onClick={()=>withdraw()} className='withdraw_btn'>Withdraw </button>
              }
            </div>
          </Box>
        </Grid>
        <Grid item  lg={3} md={12} sm={12} xs={12}>
          <Box sx={{bgcolor:"#fff", padding:'30px 40px', borderRadius:'12px'}}>
          <div sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <img src={withdrawIcon} className='withdraw_Icon'/>
            <span className='dashboard_right_heading'> Balance UNP </span>
            </div>
            <div>
              <span className='dashboard_right_balance'>{numberWithCommas(vestingBalance)}</span>
            </div>
          </Box>
        </Grid>
        <Grid item  lg={3} md={12} sm={12} xs={12}>
          <Box sx={{bgcolor:"#fff", padding:'30px 40px', borderRadius:'12px'}}>
            <div>
              <img src={purchaseIcon} className='purchase_Icon'/>
              <span className='dashboard_right_heading'>Purchased UNP</span>
            </div>
            <div>
              <span className='dashboard_right_balance'>{numberWithCommas(totalTokenpurchased) }</span>
            </div>
          </Box>
        </Grid>
      </Grid>
      
      {/* 2nd Boxes Row */}
      <Grid spacing={4} container alignItems='center' direction={{sm:'column', lg:'row', md:'row', xs:'column'}} justifyContent='center' lg={12} md={12} sm={12} style={{marginTop:'15px'}}>
        <Grid item justifyContent='space-between' alignItems='center' lg={6} md={12} sm={12} xs={12} direction={'column'} >
          <Box className='investerType-Container' container sx={{display:'flex', justifyContent:"center", bgcolor:"#fff", padding:'30px 15px', borderRadius:'12px'}} flexDirection={'column'}>
          <Grid container className='investorBox'>
                <Grid container alignItems="center" justifyContent="flex-start" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-head'>Investor Type</Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-value'>{saleType}</Typography>
                </Grid>
          </Grid>
            <Grid container>
                <Grid container alignItems="center" justifyContent="flex-start" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-head'>Total Allocation</Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-value'>{numberWithCommas(totalTokenpurchased) }</Typography>
                </Grid>
            </Grid>
            <Grid className='investorTypeBoxContainer' container>
                <Grid container alignItems="center" justifyContent="flex-start" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-head'>Initial Release</Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-value'>{numberWithCommas (initialRelease)}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container alignItems="center" justifyContent="flex-start" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-head'>Locking Period</Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-value'>{lockingPeriod}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container alignItems="center" justifyContent="flex-start" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-head'>Linear Vesting Start Date</Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-value'>{startDate}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container alignItems="center" justifyContent="flex-start" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-head'>Linear Vesting End Date</Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-value'>{endDate}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container alignItems="center" justifyContent="flex-start" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-head'>Token Available Per Day</Typography>
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" md={6} sm={6} xs={6}>
                  <Typography paragraph className='investor-value'>{tokenPerDay}</Typography>
                </Grid>
            </Grid>

          </Box>
        </Grid>

        <Grid item   justifyContent='center' alignItems='center' lg={6} md={12} sm={12} xs={12} direction={'row'} >
          <Box className={'UNP-dashboard'} container alignItems={'center'} style={{background:'#fff', borderRadius:'12px', padding:'40px 20px ',}}>
              {lists.length > 0 ? lists.map((data, i) => {
              const link = process.env.REACT_APP_ETHERSCAN_TRANSACTION_HASHLINK +''+data.transactionHash;
              return(
                <a className='stackcon' href={link} target="blank" key={i}>
                <Grid container alignItems="center" justifyContent="center" lg={12} style={{background:'#f9f9fa', margin:'25px 0px', paddingTop:'7px', paddingBottom:'7px', borderRadius:'8px'}}>
                  <Grid lg={3}>
                  <Typography className='trans-heading' paragraph> UNP </Typography >
                  <Typography  className='trans-value' paragraph>{data.amount}</Typography > 
                  </Grid>
                  <Grid lg={4}>
                  <Typography  className='trans-heading' paragraph>Hash</Typography >
                  <Typography  className='trans-value'  paragraph>{data.transactionHash.slice(0,5)}...{data.transactionHash.slice(-5)}</Typography > 
                  </Grid>
                  <Grid lg={5}>
                  <Typography  className='trans-heading' paragraph>Withdraw Date</Typography >
                  <Typography  className='trans-value' paragraph>{data.time}</Typography >
                  </Grid>
                </Grid>
                </a>
                )
            })
            : <><div className='col'> 
                <span className='bottom_right_heading' >
              UNP Dashboard
                </span>
            </div> 
        
            </>  }
            </Box>
        </Grid>
        
      </Grid>
    </Grid>
  
    </div>
   
    <Footer/>
    </>   
    
       
        
  )
}

export default Dashoboard
