import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {connectWallet, disconnect} from "./redux/WalletAction";
import {
    BrowserRouter,
    Routes,
    Route, Navigate,
} from "react-router-dom";

import "./App.css";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import Admin from "./components/Admin/Admin";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Box, CircularProgress} from "@material-ui/core";

const App = () => {
    const wallet = useSelector(state => state.WalletConnect);
    const dispatch = useDispatch();

    useEffect(() => {
        const {web3Modal} = wallet;
        if (web3Modal.cachedProvider) {
            dispatch(connectWallet());
        } else {
            dispatch(disconnect());
        }
    }, []);

    if (wallet.loading) return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
    >
        <CircularProgress/>
    </Box>

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route exact path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                    <Route exact path="/admin" element={<PrivateRoute><Admin/></PrivateRoute>}/>
                    <Route path={'*'} element={<Navigate to={'/'}/>}/>
                </Routes>
                <ToastContainer/>
            </BrowserRouter>
        </div>
    );
}

function PrivateRoute({children}) {
    const wallet = useSelector(state => state.WalletConnect);
    return wallet.connected ? children : <Navigate to={'/'}/>;
}

export default App;
